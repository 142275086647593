import { Button, TextInput } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm, zodResolver } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { useCallback, useMemo } from 'react';
import { Bot, CreateBotRequest, usePostV1Bots } from '../../api';
import { ApiResponse } from '../../api/mutator/custom-instance';
import { schema } from './schema';

interface Props {
  onSuccess: (
    data: ApiResponse<Bot>,
    variables: { data: CreateBotRequest },
    context: unknown,
  ) => unknown;
  onError: (error: FormErrors, variables: { data: CreateBotRequest }, context: unknown) => unknown;
}

export function Form({ onSuccess, onError }: Readonly<Props>) {
  const botMutation = usePostV1Bots({
    mutation: { onSuccess, onError },
  });

  const handleSubmit = useCallback(
    (values: CreateBotRequest) =>
      botMutation.mutate({
        data: {
          meeting_url: values.meeting_url,
          bot_name: values.bot_name,
          join_at: values.join_at,
        },
      }),
    [botMutation.mutate],
  );

  const defaultValues = useMemo<CreateBotRequest>(
    () => ({
      meeting_url: '',
      bot_name: '',
      join_at: new Date().toISOString(),
    }),
    [],
  );

  const form = useForm<CreateBotRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit, console.error)}>
      <TextInput
        autoFocus
        mb="md"
        label="Meeting URL"
        type="url"
        placeholder="https://zoom.us/123"
        required
        {...form.getInputProps('meeting_url')}
      />
      <TextInput
        mb="md"
        label="Bot Name"
        placeholder="My Assistant"
        required
        {...form.getInputProps('bot_name')}
      />
      <DateTimePicker
        mb="md"
        label="Join At"
        placeholder="Join At"
        minDate={new Date()}
        {...form.getInputProps('join_at')}
      />

      <Button fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
}

import {
  Anchor,
  Flex,
  Grid,
  LoadingOverlay,
  Paper,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import ColorHash from 'color-hash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Link, useParams } from 'react-router-dom';
import { useGetV1TranscriptsId } from '../../api';

dayjs.extend(utc);

export function View() {
  const { transcriptId } = useParams();
  const theme = useMantineColorScheme();

  const { data, error, isLoading } = useGetV1TranscriptsId(String(transcriptId), {
    query: { enabled: Boolean(transcriptId) },
  });

  if (isLoading || !data) {
    return <LoadingOverlay visible={true} />;
  }

  if (error) {
    return <h1>{error.message}</h1>;
  }

  let lightness = 0.3;
  if (theme.colorScheme === 'dark') {
    lightness = 0.7;
  }
  const colorHash = new ColorHash({ lightness });
  return (
    <Grid gutter="md">
      <Grid.Col span={8}>
        {!data.body.monologues ? (
          <Paper p="md" mb="md" radius="sm">
            <Text>No monologues found</Text>
          </Paper>
        ) : (
          data.body.monologues.map((monologue) => {
            const color = colorHash.hex(monologue.speaker_id);
            const start = dayjs.utc(monologue.start * 1_000).format('HH:mm:ss');
            const end = dayjs.utc(monologue.end * 1_000).format('HH:mm:ss');

            return (
              <Paper key={monologue.speaker_id} p="md" mb="md" radius="sm">
                <Flex justify="space-between" c={color}>
                  <Text mb="sm">{monologue.speaker_id}</Text>
                  <Text mb="sm">
                    {start} - {end}
                  </Text>
                </Flex>
                <Text>{monologue.text}</Text>
              </Paper>
            );
          })
        )}
      </Grid.Col>
      <Grid.Col span={4}>
        <Paper p="lg" radius="ms">
          <Text mb="sm">
            <strong>Level</strong>: {data.body.level}
          </Text>
          <Text mb="sm">
            <strong>Status</strong>: {data.body.status}
          </Text>
          <Text mb="sm">
            <strong>Duration</strong>: {data.body.duration_in_seconds}s
          </Text>
          <Text mb="sm">
            <strong>Date</strong>:{' '}
            {new Date(data.body.recorded_at ?? data.body.created_at).toLocaleString()}
          </Text>
          {data.body.speakers && (
            <Text>
              <strong>Speakers</strong>:
              <ul>
                {data.body.speakers.map((speaker) => (
                  <li key={speaker.id}>
                    {speaker.id}: {speaker.name}
                  </li>
                ))}
              </ul>
            </Text>
          )}
          {Boolean(data.body.metadata) && (
            <Text>
              <strong>Metadata</strong>:
              <ul>
                {Object.entries(data.body.metadata as Record<string, unknown>).map(
                  ([key, value]) => (
                    <li key={key}>
                      {key}: {value as string}
                    </li>
                  ),
                )}
              </ul>
            </Text>
          )}
          {data.body.bot_id && (
            <Text>
              <strong>Bot</strong>:
              <Anchor component={Link} to={`/bots/${data.body.bot_id}`}>
                <IconLink />
              </Anchor>
            </Text>
          )}
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

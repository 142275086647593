import {
  Center,
  Group,
  LoadingOverlay,
  Paper,
  rem,
  RingProgress,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { IconHourglass, IconReportMoney, IconTextScan2 } from '@tabler/icons-react';
import { useGetV1Me } from '../api';

export function Dashboard() {
  const { data, isLoading, isSuccess } = useGetV1Me();
  if (isLoading) return <LoadingOverlay visible />;

  if (!isSuccess) return <h1>Not ready</h1>;

  const stats = [
    {
      label: 'Transcripts',
      stats: data.body.count,
      progress: data.body.count / 10,
      color: 'teal',
      icon: IconTextScan2,
    },
    {
      label: 'Usage',
      stats: `${Math.round(data.body.usage)} minutes`,
      progress: data.body.usage / 100,
      color: 'blue',
      icon: IconHourglass,
    },
    {
      label: 'Cost',
      stats: `$${data.body.cost}`,
      progress: data.body.cost / 100,
      color: 'purple',
      icon: IconReportMoney,
    },
  ] as const;

  return (
    <SimpleGrid cols={{ base: 1, sm: 3 }}>
      {stats.map((stat) => {
        const Icon = stat.icon;
        return (
          <Paper withBorder radius="md" p="xs" key={stat.label}>
            <Group>
              <RingProgress
                size={80}
                roundCaps
                thickness={8}
                sections={[{ value: stat.progress, color: stat.color }]}
                label={
                  <Center>
                    <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
                  </Center>
                }
              />

              <div>
                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                  {stat.label}
                </Text>
                <Text fw={700} size="xl">
                  {stat.stats}
                </Text>
              </div>
            </Group>
          </Paper>
        );
      })}
    </SimpleGrid>
  );
}

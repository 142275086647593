import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Group,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { Logo } from './Logo';
import { MainLinks } from './MainLinks';
import { SelectOrg } from './SelectOrg';
import { User } from './User';

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Readonly<Props>) {
  const theme = useMantineTheme();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [opened, { toggle }] = useDisclosure();
  return (
    <AppShell
      header={{ height: 60 }}
      footer={{ height: 50 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      styles={{
        main: {
          background: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
    >
      <AppShell.Header>
        <Group justify="space-between" p="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Logo />
          <ActionIcon variant="default" onClick={toggleColorScheme} size={30}>
            {colorScheme === 'dark' ? <IconSun size={16} /> : <IconMoonStars size={16} />}
          </ActionIcon>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar>
        <AppShell.Section grow>
          <SelectOrg />
          <MainLinks />
        </AppShell.Section>
        <AppShell.Section>
          <User />
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main h="100vh">
        <Box pos="relative" h="100%" p="md">
          {children}
        </Box>
      </AppShell.Main>
      <AppShell.Footer>
        <Group justify="center" align="center" p="sm">
          From 🇵🇹 with ❤️
        </Group>
      </AppShell.Footer>
    </AppShell>
  );
}
